div.card{
  display: flex;
  flex-direction: row;
  align-items: center;
  
  background: var(--bg-neutral-gray-05);
  transition: background 300ms, box-shadow 300ms;

  border-radius: 4px;
  position: relative;
  box-sizing: border-box;

  &-full{
    width: 100%;
  }

  &-disabled{
    background: var(--neutral-gray-03);
  }

  &:hover {
    background: var(--bg-neutral-gray-09);
    box-shadow: 0px 1px 12px 0px var(--black-02);
  }
}
.card-sticky{
  position: sticky!important;
}
