$neutralWhite: var(--white);
$neutralDark: var(--Text-colors-Text-Secondary);
$neutralGrayDark: var(--neutral-gray-04);
$neutralGray: var(--neutral-gray-01);
$neutralGray6: var(--neutral-gray-06);
$errorRed: var(--error-red);
$tertiarBrownLight: var(--tertiary-brown-900);
$purpleDark: var(--primary-purple-900);

div.form-field{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 40px;
  padding: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: $neutralWhite;

  margin-top: 23px;
  margin-bottom: 16px;

  border: 1.604px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.00);

  &-prefix{
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    height: 38px;
    border-radius: 2px 0px 0px 2px;
    padding: 8px;
    background-color: $neutralGray;
    border-right: 1px solid $neutralGrayDark;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $neutralDark;
    transform: translateX(-12px);
    overflow: hidden;

    svg{
      width: 34px;
      height: 34px;
      margin-right: 4px;
    }
  }

  &-sufix{
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-radius: 2px 0px 0px 2px;
    padding-left: 8px;
    overflow: hidden;
    cursor: pointer;

    svg {
      path{
        fill:$neutralGrayDark;
      }
    }
  }

  &:focus-within{
    box-shadow: 0 0 2px  $neutralDark;
  }

  &.input-error{
    border-color: $tertiarBrownLight;
    border-width: 2px;
    margin-bottom: 24px;
  }


  label{
    position: absolute;
    top: -22px;
    left: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 1rem;
    color: $neutralDark;
  }

  input{
    background-color: transparent;
    flex-grow: 1;
    font-size: 0.9rem;
    color: $neutralDark;
    box-sizing: border-box;
    border: none;
    margin: 0;
    &:focus-visible{
      outline: none;
    }

    &::placeholder{
      color: $neutralGrayDark!important;
    }

    &[type="password"]:not(:placeholder-shown) {
      font-family: caption;
      font-size: 16px;
    }
  }

  input:disabled, &.field-disabled {
    background-color: $neutralGray;
  }

  &  .input-error-label{
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $tertiarBrownLight;

    @media(min-width: 992px){
      font-weight: 600;
    }
  }
}

p.ctv-error-message + div.form-field-blocks-8-digits {
  @media(max-width: 576px) {
    margin-top: 24px;
  }
}

div.form-field-blocks-8-digits {
  margin-top: 40px;
  justify-content: center;
  gap: 24px 8px !important;
}

div.form-field-blocks{
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;

  &__separator {
    margin: auto 0;
    font-size: 32px;
    color: $neutralGray6;
  }

  &__box.border-error + &__separator {
    color: $tertiarBrownLight !important;
  }

  &__box{
    box-sizing: border-box;
    width: 48px;
    height: 64px;
    padding: 4px;
    border: 1px solid $neutralGrayDark;
    background-color: $neutralWhite;
    border-radius: 4px;
    overflow: hidden;

    @media(max-width: 576px){
      width: 30px;
      height: 48px;
    }

    input {
      text-align: center;
      box-sizing: border-box;
      outline: none;
      border: none;
      width: 100%;
      height: 100%;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      margin: 0;
      max-width: 50px;

      @media(max-width: 576px){
        padding: 0;
        text-align: center;
        font-size: 24px;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }

      &[type=number] {
          -moz-appearance: textfield;
      }
    }

    &:nth-child(4):nth-last-child(3) {
      margin-left: 20px;
    }

    @media(min-width: 576px) {
      &:nth-child(5):nth-last-child(4) {
        margin-left: 20px;
      }
    }

    &.border-filled{
      border-color: $purpleDark;
      border-width: 2px;
    }

    &.border-error{
      border-color: $tertiarBrownLight;
      border-width: 2px;
    }
  }
}

.switch-toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}
.switch-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $neutralGrayDark;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: $neutralWhite;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: $purpleDark;
}

input:focus + .slider {
  box-shadow: 0 0 1px $purpleDark;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 50%;
}
.error-token{
  margin-top: 4px;
  color: $tertiarBrownLight;
}
.select-container{
  position: relative;
}

.form-select-auto-complete {
  display: flex;
  flex-direction: column;
  border: 1px solid $neutralDark;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: $neutralWhite;
  width: 100%;
  position: absolute;
  top: 62px;
  left: 0;
  z-index: 99;
  max-height: 250px;
  overflow: auto;

  span{
    width: 100%;
    cursor: default;
    padding: 8px 12px;
    height: 40px;
    max-height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    &:hover{
      background-color: rgba(0, 0, 0, 0.032)
    }
  }
}

.form-select-input input{
  text-transform: lowercase;
  pointer-events: none;
}
.form-select{
  display: flex;
  flex-direction: column;
  border: 1px solid $neutralDark;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: $neutralWhite;
  width: 100%;
  z-index: 99;
  transform: translateY(-1px);

  span{
    width: 100%;
    cursor: default;
    padding: 8px 12px;
    height: 40px;
    max-height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-transform: lowercase;
    &:hover{
      background-color: rgba(0, 0, 0, 0.032)
    }
  }
}
