.button{
    cursor: pointer;
    transition: background 300ms, color 300ms, border 300ms;

    position: relative;
    display: flex;
    padding: 12px 64px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    font-size: 1rem;
    font-weight: 600;
    font-style: normal;

    border: none;
    border-radius: 8px;
    
    background: transparent;
    color: var(--Text-colors-Text-Secondary);
    z-index: 0;

    span::first-letter {
        text-transform: capitalize;
    }

    &:not([disabled]):hover{
        color: var(--Text-colors-Text-Primary);
        background: #FFF;
    }

    @media(max-width: 576px){
        margin-bottom: 32px;
    }

    &.btn-full{
      width: 100%;
    }

    &.btn-primary {
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: inherit;
            z-index: -1;
    
            padding: 1.6px;
    
            background: var(--Rainbow-Colors-Rainbow);
    
            -webkit-mask: 
                linear-gradient(#fff 0 0) content-box, 
                linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask: 
                linear-gradient(#fff 0 0) content-box, 
                linear-gradient(#fff 0 0);
            mask-composite: exclude; /* para navegadores suportados */
        }

        &::after {
            position: absolute;
            content: "";
            inset: 0;
            background-image: var(--Rainbow-Colors-Rainbow-Faded);;
            z-index: 1;
            opacity: 0;
            transition: opacity 300ms linear;
            border-radius: 8px;
        }


        &:not([disabled]):hover::after {
           opacity: 1;
        }

        &:disabled {
            background: var(--Rainbow-Colors-Rainbow-Faded);
        }

        &:disabled::before {
            background: transparent;
        }
    }

    &.btn-outline,
    &.btn-white {
        background: #0000;
        transition: color 300ms;
        color: var(--Text-colors-Text-Secondary);
    }

    &.btn-outline {
        border: 1.6px solid var(--Text-colors-Button-Text-Disabled);

        &:hover {
            border: 1.6px solid var(--Rainbow-Colors-Rainbow-Color-34);
        }

        &:active {
            border: 1.6px solid var(--Text-colors-Text-Secondary);
        }
    }

    &.btn-red{
        color: var(--white);
        background: var(--tertiary-brown-900);
    }

    &:disabled {
        cursor: default;
    }
}
