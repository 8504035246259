.logo-text{
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  svg {
    width: 3rem;
    height: 3rem;
  }

  span{
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

}
