.container{
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    padding: 24px;

    &__opened{
        height: 100%;
        padding: 24px;
        padding-bottom: 16px !important;
        flex-direction: column;
        flex: 1;
    }

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
    }
}

@media(max-width: 576px){
    .container{
      padding: 0px;
    }
    .container > div.card {
        margin: inherit!important;
        width: 100vw!important;
        height: 100vh!important;
    }
}
