$purpleDark: var(--primary-purple-900);
$neutralGray: var(--neutral-gray-01);
$neutralDark: var(--neutral-dark-05);
div.menu-button{
  position: relative;
  width: fit-content;
  box-sizing: border-box;
  z-index: 99999;
  button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  &.menu-active{
    border-bottom: 2px solid $purpleDark;
    button{
      color: $purpleDark;
    }
  }

  &-items{
    position: absolute;
    right: 0;
    top: calc(100% + 12px);
    width: max-content;
     .card{
      display: flex;
      padding: 8px 16px!important;
      flex-direction: column;
    }
  }

  &-item{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 0;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $neutralDark;
    gap: 16px;
    border-bottom: 1px solid $neutralGray;
    box-sizing: border-box;
    svg{
      margin-left: auto;
    }
  }
}
