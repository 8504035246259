.nav-bar{
    display: flex;

    height: 48px;
    max-height: 48px;

    margin: 12px;
    padding: 12px;
    
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: 4px 4px 4px 4px;
    background: var(--bg-neutral-gray-07);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11);
    z-index: 999;

    @media(max-width: 902px){
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 12px;
    }

    &__back{
        svg{
          display: none;
        }
        @media(max-width: 902px){
            svg{
                display: block;
            }
        }
    }

    &__logo{
        margin-left: 104px;
        cursor: pointer;
        @media(max-width: 902px){
            margin-left: inherit;
            justify-self: center;
        }
    }

    &__menu{
        margin-left: auto;
        margin-right: 16px;
        button{
            white-space: nowrap;
        }
        @media(max-width: 902px){
            margin-left: inherit;
            justify-self: end;
            margin-right: 8px;
        }
    }

    .logo-text{
        margin-bottom: 0;
        span{
            white-space: nowrap;
        }
    }

    &-btn-icon{
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
}
