:root{
  --primary-purple-700: #8539B6;
  --primary-purple-300: #E18DFF;
  --primary-purple-900: #550087;
  --primary-purple-800: #8200C8;

  --secondary-green-300: #2CB477;
  --secondary-green-50: #8EFFC9;
  --secondary-green-800: #005B28;
  --secondary-green-900: #00371E;

  --error-red: #E3332A;

  --tertiary-red-400: #EF4846;
  --tertiary-pink-50: #FFBDAD;
  --tertiary-brown-800: #A40012;
  --tertiary-brown-900: #A32929;

  --neutral-gray-01: #D2D2D2;
  --neutral-gray-02: #BBB;
  --neutral-gray-03: #DDD9E0;
  --neutral-gray-04: #7A7A7A;
  --neutral-gray-06: #BAB8B8;

  --neutral-dark-05: #373737;
  --neutral-dark-01: #282828;

  --white: #FFFFFF;
  --hover: #f0f0f0;

  --black-02: rgba(0, 0, 0, 0.20);

  --bg-neutral-gray-05: rgba(255, 255, 255, 0.50);
  --bg-neutral-gray-07: rgba(255, 255, 255, 0.70);
  --bg-neutral-gray-09: rgba(255, 255, 255, 0.90);

  --Text-colors-Text-Primary: #323232;
  --Text-colors-Text-Secondary: #525252;
  --Text-colors-Text-Tertiary: #616161;
  --Text-colors-Button-Text-Disabled: #c7c7c7;

  --Rainbow-Colors-Rainbow-Faded: linear-gradient(
    90deg,
    rgba(192, 206, 27, 0.10) 0%,
    rgba(118, 146, 39, 0.10) 17%,
    rgba(61, 136, 170, 0.10) 34%,
    rgba(101, 55, 138, 0.10) 51%,
    rgba(222, 128, 36, 0.10) 66%,
    rgba(230, 93, 42, 0.10) 82%,
    rgba(192, 56, 38, 0.10) 100%
  );
  --Rainbow-Colors-Rainbow: linear-gradient(
    90deg,
    #C0CE1B 0%,
    #769227 17%,
    #3D88AA 34%,
    #65378A 51%,
    #DE8024 66%,
    #E65D2A 82%,
    #C03826 100%
  );

  --Rainbow-Colors-Rainbow-Color-34: #3D88AA;
}
